<script>
import Banner from "@/views/components/Banner.vue";
import Breadcrumb from "@/views/components/Breadcrumb.vue";
import LeftMenu from "@/views/components/LeftMenu.vue";
import TitleImage from "@/views/components/TitleImage.vue";
import viewDetailsImage from "@/views/components/ViewDetailsImage.vue";
import LearnMore from "@/views/components/LearnMore.vue";
import notData from "@/views/components/Not_data.vue";
import ArtImageList from "@/views/components/ArtImageList.vue";
import { styleImages, tabsOption } from "@/views/artist/moke";

export default {
  name: "index",
  components: {
    LeftMenu,
    Breadcrumb,
    Banner,
    TitleImage,
    viewDetailsImage,
    notData,
    // eslint-disable-next-line vue/no-unused-components
    LearnMore,
    ArtImageList
  },
  data() {
    return {
      tabsOption: tabsOption, // 标签页
      currentTab: {}, // 当前选中的标签页数据
      breadcrumbOption: [], // 面包屑
      // currentSelectMenu: {}, // 当前选中的菜单节点
      childSelect: {}, // 页面选中的数据
      styleSelIndex: "bsf", // 风格样式选中的的下标
      styleStartIndex: 0, // 风格显示的开始下标
      styleEndIndex: 5, // 风格显示的结束下标
      styleLength: 0, // 风格数组的总长度
      styleImages: styleImages["bsf"] // 风格内的照片
    };
  },
  watch: {
    // childSelect(newVal) {
    //   // console.log("newVal", newVal);
    // }
  },
  mounted() {
    this.currentTab = this.tabsOption[0];
    this.childSelect = this.tabsOption[0];
    this.checkTab(this.currentTab.key);
  },
  methods: {
    clearData() {
      // 初始化三级目录
      this.currentTab = {};
      this.childSelect = {};
      this.styleSelIndex = ""; // 风格样式选中的的下标
      this.styleStartIndex = 0; // 风格显示的开始下标
      this.styleEndIndex = 5; // 风格显示的结束下标
      this.styleLength = 0; // 风格数组的总长度
      this.styleImages = []; // 风格内的照片
    },
    /**
     * 切换一级目录
     * @param key
     */
    checkTab(key) {
      // 初始化三级目录
      this.clearData();
      const tabsOption = this.tabsOption;
      tabsOption.map(item => {
        if (item.key === key) {
          this.currentTab = item;
          // 添加面包屑
          const currentOption = [
            {
              key: "artist",
              title: "艺术家",
              toPath: "/artist"
            }
          ];
          currentOption.push({
            key: item.key,
            title: item.title,
            toPath: "/artist"
          });
          this.breadcrumbOption = currentOption;
        }
      });
    },
    /**
     * 切换二级，三级目录保存数据
     * @param item
     * @param menuCount 面包屑的位数
     */
    checkChildSelect(item, menuCount) {
      // 添加面包屑 （暂时关闭）start
      const currentOption = this.breadcrumbOption;
      const isFlog = currentOption.some(o => o.key === item.key);
      if (!isFlog) {
        const newOption = {
          key: item.key,
          title: item.title,
          toPath: "/artist?sel=" + item.key
        };
        currentOption.splice(menuCount, menuCount + 1, newOption);
        this.breadcrumbOption = currentOption;
      }
      // 添加面包屑 end
      // 没有三级目录，三级目录不需要选中当前的菜单节点
      if (!item.menuChild || item.menuChild.length === 0) {
        this.childSelect = item; // 设置当前选中的内容
        if (item.styleOption) {
          // 设置风格的最大长度
          this.styleLength = item.styleOption.length - 1;
          this.styleSelIndex = item.styleOption[0].key;
          this.styleImages = styleImages[item.styleOption[0].key];
        } else {
          // 设置风格的最大长度
          this.styleLength = 0;
          this.styleSelIndex = "";
          this.styleImages = [];
        }
      } else {
        // 清除数据
        this.childSelect = {};
      }
    },

    checkCurrentTab(data) {
      this.currentTab = data;
    },

    /**
     * 点击风格
     * @param item
     */
    styleOnClick(item) {
      this.styleSelIndex = item.key;
      this.styleImages = styleImages[item.key];
    },
    /**
     * 切换风格
     * @param direction
     */
    checkStyleIndex(direction) {
      const start = this.styleStartIndex;
      const end = this.styleEndIndex;
      const lengthCount = this.styleLength;
      if (direction === "left") {
        if (start > 0) {
          this.styleStartIndex = start - 1;
          this.styleEndIndex = end - 1;
        }
      } else if (direction === "right") {
        if (end < lengthCount) {
          this.styleStartIndex = start + 1;
          this.styleEndIndex = end + 1;
        }
      }
    }
  },
  destroyed() {
    this.tabsOption = [];
    this.breadcrumbOption = [];
    this.clearData();
  }
};
</script>

<template>
  <div class="">
    <Banner
      :bannerSrc="require('@/assets/banner/art.jpg')"
      :tabs="tabsOption"
      :selTab="currentTab.key"
    />
    <div class="padding-comm-warp _content_warp">
      <div class="left">
        <LeftMenu :currentTab="currentTab" :childSelect="childSelect" />
      </div>
      <div class="right">
        <Breadcrumb :breadcrumbList="breadcrumbOption" />
        <div class="content">
          <div v-if="childSelect && Object.keys(childSelect).length !== 0">
            <div class="top_warp" v-if="childSelect.imgTitle">
              <div class="top_warp_left">
                <el-image :src="childSelect.img" fit="scale-down" lazy/>
              </div>
              <div class="top_warp_right">
                <!--                标题-->
                <TitleImage :title="childSelect.imgTitle" />
                <!--                材质-->
                <div class="art_params_comm">
                  <div>1、材质：{{ childSelect.material }}</div>
                  <div>2、尺寸：{{ childSelect.dimension }}</div>
                  <div>3、年代：{{ childSelect.age }}</div>
                  <div>4、作者：{{ childSelect.author }}</div>
                  <div>5、作品解说：{{ childSelect.desc }}</div>
                </div>
                <!--                风格-->
                <div class="zp_style">
                  <div class="jt">
                    <el-image
                      :src="require('@/assets/art/jt_left.png')"
                      v-on:click="checkStyleIndex('left')"
                      lazy
                    />
                    <el-image
                      :src="require('@/assets/art/jt_right.png')"
                      v-on:click="checkStyleIndex('right')"
                      lazy
                    />
                  </div>
                  <div class="style_content">
                    <div
                      :class="
                        styleItem.key === styleSelIndex
                          ? 'style_content_item style_content_item_check'
                          : 'style_content_item'
                      "
                      v-for="(styleItem, index) in childSelect.styleOption"
                      :key="styleItem.key"
                      v-on:click="styleOnClick(styleItem)"
                      v-show="
                        index >= styleStartIndex && index <= styleEndIndex
                      "
                    >
                      <div>{{ styleItem.name }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="styleImages && styleImages.length !== 0">
              <ArtImageList :images="styleImages" />
            </div>
            <div v-else>
              <notData />
            </div>
          </div>
          <div v-else class="h">
            <div
              v-for="item in currentTab.descList"
              :key="item.key"
              class="h_item"
            >
              <!--              左边详情-->
              <div class="h_item_left">
                <!--                标题-->
                <TitleImage :title="item.title" />
                <!--                材质-->
                <div class="art_params_comm">
                  <div>材质：{{ item.material }}</div>
                  <div>尺寸：{{ item.dimension }}</div>
                  <div>年代：{{ item.age }}</div>
                  <div>作者：{{ item.author }}</div>
                </div>
                <div class="desc">
                  {{ item.desc }}
                  <viewDetailsImage />
                </div>
              </div>
              <div class="h_item_right">
                <el-image :src="item.img" lazy/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "../index.scss";

._content_warp {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}
.content {
  .art_params_comm {
    margin-top: 40px;
    line-height: 30px;
  }
}
.content .top_warp {
  display: flex;
  .top_warp_left {
    margin-right: 30px;
  }
  .top_warp_right {
    width: 50%;
    .zp_style {
      margin-top: 10px;
    }
    .zp_style .jt {
      width: 134px;
      display: flex;
      justify-content: space-between;
    }
    .zp_style .style_content {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
    }
    .zp_style .style_content .style_content_item {
      //width: 74px;
      //height: 228px;
      width: 12%;
      //height: 25%;
      line-height: 30px;
      writing-mode: vertical-rl;
      cursor: pointer;
      //padding: 22px 28px;
      padding: 4%;
      letter-spacing: 0.3rem;
      background: url("../../assets/art/style.png") no-repeat;
      background-size: cover; /* 背景图片覆盖整个容器 */
      background-position: center; /* 背景图片居中 */
      color: $theme-red;
      font-size: 1.4rem;
      border: 0.1rem solid $theme-red;
      box-shadow: inset 0 0 2px 0px $theme-red;
    }
    .zp_style .style_content .style_content_item_check,
    .zp_style .style_content .style_content_item:hover {
      background: url("../../assets/art/style_sel.png") no-repeat;
      background-size: cover; /* 背景图片覆盖整个容器 */
      background-position: center; /* 背景图片居中 */
      color: #ffffff;
      transform: scale(1.06);
      transition: all 1s;
      border: 0;
      box-shadow: inset 0 0 0px 0px $theme-red;
    }
  }
}
.content .h .h_item {
  margin-bottom: 60px;
  display: flex;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.2);
  .h_item_left {
    width: 40%;
    padding: 26px;
    .desc {
      margin-top: 30px;
      line-height: 30px;
      font-size: 0.8rem;
      cursor: pointer;

      /deep/ {
        .el-image__inner {
          margin: 20px 0 0 10px;
          width: 20px;
        }
      }
    }
  }
  .h_item_right {
    width: calc(100% - 300px);
    display: flex;
    justify-content: end;
    .el-image {
      height: 100%;
    }
  }
}
.el-image {
  cursor: pointer;
}
.el-image:hover {
  transform: scale(1.06);
  transition: all 1s;
}
</style>
