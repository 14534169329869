<script>
export default {
  name: "TitleImage",
  props: {
    title: {
      type: String
    },
    fontSize: {
      type: String,
      default: "1.2rem"
    }
  }
};
</script>

<template>
  <div class="title_warp">
    <el-image
      :src="require('@/assets/common/titleLeft.png')"
      fit="scale-down"
      lazy
    />
    <span :style="{ fontSize: fontSize }">{{ title }}</span>
    <el-image
      :src="require('@/assets/common/titleRight.png')"
      fit="scale-down"
      lazy
    />
  </div>
</template>

<style scoped lang="scss">
@import "../index.scss";
.title_warp {
  position: absolute;
  /deep/.el-image {
    position: relative;
    top: 6px;
  }
  span {
    color: $theme-red;
    padding: 0 10px;
    font-size: 1.4rem;
  }
}
</style>
